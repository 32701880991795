<template>
  <!-- Min height set to avoid CLS (Cumulative Layout Shift) penalties -->
  <div class="min-h-[435px] lg:min-h-[550px] 3xl:min-h-[800px]">
    <client-only>
      <EQPECentraSearchController
        :product-list-component="'ProductListSwipeable'"
        :centra-category-id="centraCategoryId"
        :centra-mp-gender="centraMpGenderList"
        :title="title"
        :link="link"
        :hide-product-count="hideProductCount"
        :swipeable="true"
      />
    </client-only>
  </div>
</template>
<script>
import { isNotEmptyString } from '@made-people/types-not-empty-string'

export default {
  name: 'ProductListSwipeableWrapper',
  props: {
    centraCategoryId: {
      type: Array,
      default: () => [],
    },
    centraMpGender: {
      type: [Array, String],
      default: () => [],
    },
    title: {
      type: String,
      default: undefined,
    },
    link: {
      type: Object,
      default: undefined,
    },
    hideProductCount: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * List of genders to filter on
     *
     * Normally centraMpGender is an array of strings but in one case, in the PDP usp for Sweden, it was just a string.
     *
     * @returns {string[]}
     */
    centraMpGenderList() {
      const valueFromStoryblok = this.centraMpGender
      if (typeof valueFromStoryblok === 'string') {
        const trimmedValueFromStoryblok = valueFromStoryblok.trim()
        if (isNotEmptyString(trimmedValueFromStoryblok)) {
          return [trimmedValueFromStoryblok]
        }
        return []
      }
      if (Array.isArray(valueFromStoryblok)) {
        return valueFromStoryblok.filter(isNotEmptyString)
      }
      return []
    },
  },
}
</script>
